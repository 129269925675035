.RSVP {
  position: relative;
  background-color: #222;
  color: #bbb;

  .header {
    color: #bbb;
    background-image: url("./images/rsvp-invitation.jpg");
    background-position: left !important;
    padding-bottom: 100px;
    height: 500px;
    box-shadow: 0 4px 16px rgba(black, 0.5);

    .rsvp-status {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 40px;
      text-align: center;
      color: white;
      z-index: 2;
      font-size: 32px;

      p {
        text-shadow: 0px 2px 25px black;
      }
    }
  }

  @media only screen and (max-width: 865px) {
    .header {
      height: 350px;
    }
  }

  .guest-container {
    padding: 60px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
  }
}

.Site.RSVP.header.overlay-shadow.bottom {
  background: linear-gradient(rgba(0, 0, 0, 0), #222);
}
