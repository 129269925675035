.Home {
  .invite-banner {
    overflow: auto;
    background-image: url("./images/bg2.jpg");
    background-position: center center;
    background-size: cover;
    height: 800px;
    color: white;
    text-align: center;
    font-size: 32px;
    position: relative;

    p {
      margin: 0;
      position: relative;
      z-index: 2;

      &.invite-text {
        margin: 50px auto;
        padding: 30px;
        max-width: 530px;

        img {
          max-width: 100%;
        }
      }

      &.date {
        font-size: 24px;
        font-family: "Playfair Display SC", serif;
        position: absolute;
        bottom: 75px;
        padding: 0 30px;
        left: 0;
        right: 0;
      }
    }

    .overlay-shadow {
      width: 100%;
      position: absolute;
      z-index: 1;

      &.top {
        height: 300px;
        background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
        top: 0;
      }

      &.bottom {
        height: 250px;
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
        bottom: 0;
      }
    }
  }

  .sections {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    padding: 10px 0 0 10px;
    background-color: #111;

    .section {
      padding: 30px;
      flex-grow: 1;
      flex-basis: 410px;
      margin: 0 10px 10px 0;
      text-decoration: none;
      color: white;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: #7c6565;
      height: 320px;
      transition: all 0.4s;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        background: inherit;
        transition: inherit;
      }

      &:hover::before {
        transform: scale(1.025);
      }

      &.story {
        background-image: url("./story/images/story-header.jpg");
      }

      &.details {
        background-image: url("./images/kbyg.jpg");
      }

      &.guide {
        background-image: url("./images/travel.jpg");
      }

      &.rsvp {
        background-image: url("./images/rsvp.jpg");
      }

      &.registry {
        background-image: url("./images/registry.jpg");
      }

      h3,
      .subtext {
        position: relative;
        z-index: 3;
        text-shadow: 0 2px 12px rgba(black, 0.8);
      }

      h3 {
        padding: 0;
        margin: 0;
        font-size: 24px;
      }

      .subtext {
        opacity: 0;
        transition: opacity 0.4s;
        font-size: 18px;
        font-weight: 500;
        max-width: 280px;
      }

      &:hover {
        .subtext {
          opacity: 1;
        }
      }

      @media (hover: none) {
        .subtext {
          opacity: 1;
        }
      }
    }
  }
}
