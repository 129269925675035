.Details {
  .map {
    overflow: auto;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .header {
    background-image: url("./images/meadowood-bg.jpg");
    height: 650px;
  }

  .event-logistics {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    padding: 15px 30px;

    .overview {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-basis: 330px;
    }

    .section {
      flex-grow: 1;
      margin: 15px 0;

      &.schedule {
        flex-grow: 2;
      }

      p {
        text-align: center;
        font-size: 22px;
        color: #666;
        margin: 10px 0;

        &.date {
          font-family: "Playfair Display SC", serif;
        }

        &.address {
          a {
            background-color: #c4c4c4;
            border-radius: 10px;
            padding: 10px 20px;
            display: inline-block;
            text-decoration: none;
            color: white;
            transition: all 0.3s;

            &:hover {
              background-color: #aaa;
            }

            &:active {
              background-color: #777;
            }
          }
        }
      }

      .venue-name {
        font-family: "Playfair Display SC", serif;
        font-weight: bold;
        font-size: 22px;
      }

      table {
        width: 100%;
        border-collapse: collapse;

        thead {
          background-color: #f6f6f6;
          text-align: left;
        }

        th,
        td {
          padding: 10px 12px;
          border: 1px solid #ddd;
        }
      }
    }
  }

  .map {
    height: 728px;
    background-image: url("./images/meadowood-map.jpg");
  }

  @media only screen and (max-width: 810px) {
    .map {
      height: 300px;
    }
  }

  .dress-code {
    margin-bottom: 30px;

    h2,
    p {
      text-align: center;
    }

    p {
      font-size: 20px;
      padding: 0 60px;
    }

    .sections {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;

      .section {
        flex-grow: 1;
        flex-basis: 330px;
        padding: 60px 60px 30px;
        text-align: center;

        .icon {
          width: 75px;
          height: 75px;
          margin: 0 auto;
          background-position: center center;
          background-size: 100%;
          margin-bottom: 40px;

          &.tuxedo {
            background-image: url("./images/tuxedo.svg");
          }
          &.dress {
            background-image: url("./images/dress.svg");
          }
        }
      }
    }
  }
}
