.GuestCard {
  margin-bottom: 10px;
  background-color: rgba(#444, 0.6);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  padding: 8px 12px;
  padding: 30px;
  flex-basis: 480px;
  margin: 10px 10px 0;

  &.decline {
    // background-color: rgba(black, 0.5);
  }

  &.undecided {
    .food-options {
      opacity: 0.4;
    }

    .food-options .vert-switch .switch-option {
      cursor: default;
    }
  }

  &.locked {
    .food-options .vert-switch .switch-option,
    .switch.horiz-switch .switch-option {
      cursor: default;
    }
  }

  .guest-name {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    color: #ccc;
    padding: 0;
    margin: 0 0 20px;
  }

  .label {
    font-size: 22px;
    padding: 0;
    margin: 10px 0 16px;
    color: #bbb;
    text-align: center;
  }

  .switch {
    background-color: rgba(0, 0, 0, 0.6);
    margin-bottom: 16px;
    border-radius: 25px;

    &.horiz-switch {
      height: 50px;
      text-align: center;
      position: relative;
      padding: 0 5px;

      .selector {
        position: absolute;
        width: 33%;
        height: 40px;
        border-radius: 20px;
        background-color: rgba(#666, 0.5);
        z-index: 1;
        top: 5px;
        transition: left 0.3s;

        &.decline {
          left: 5px;
        }

        &.undecided {
          left: 33.33%;
        }

        &.accept {
          left: calc(67% - 5px);
        }
      }

      .switch-option {
        display: inline-block;
        width: 33.33%;
        height: 40px;
        border-radius: 20px;
        margin: 5px 0;
        mask-position: center center;
        mask-repeat: no-repeat;
        mask-size: 30px 30px;
        position: relative;
        z-index: 2;
        cursor: pointer;

        & + .switch-option {
          margin-left: 0;
        }

        &.decline {
          background-color: rgb(200, 45, 45);
          mask-image: url("./images/decline.svg");
        }

        &.undecided {
          background-color: #aaa;
          mask-image: url("./images/undecided.svg");
        }

        &.accept {
          background-color: rgb(45, 200, 45);
          mask-image: url("./images/accept.svg");
        }

        &.loading {
          mask-image: url("./images/spinner.svg");
        }
      }
    }

    &.vert-switch {
      position: relative;

      .selector {
        position: absolute;
        width: calc(100% - 10px);
        left: 5px;
        height: 40px;
        border-radius: 20px;
        z-index: 1;
        transition: top 0.3s;
        background: none;

        &.veg {
          background-color: rgba(#666, 0.5);
          top: 5px;
        }

        &.fish {
          background-color: rgba(#666, 0.5);
          top: 5px * 3 + 40px;
        }

        &.meat {
          background-color: rgba(#666, 0.5);
          top: 5px * 5 + 40px * 2;
        }
      }

      .switch-option {
        font-size: 22px;
        padding: 5px 18px 5px 60px;
        line-height: 40px;
        color: #888;
        position: relative;
        z-index: 2;
        cursor: pointer;

        &.active {
          background-color: white;
        }

        span {
          position: absolute;
          top: 10px;
          left: 18px;
          display: block;
          width: 30px;
          height: 30px;
          mask-position: center center;
          mask-repeat: no-repeat;
          mask-size: 30px 30px;

          &.fish {
            background-color: rgb(108, 108, 214);
            mask-image: url("./images/fish.svg");
          }

          &.veg {
            background-color: rgb(45, 200, 45);
            mask-image: url("./images/veg.svg");
          }

          &.meat {
            background-color: rgb(201, 125, 77);
            mask-image: url("./images/meat.svg");
          }

          &.loading {
            mask-image: url("./images/spinner.svg");
          }
        }
      }
    }
  }

  .dietary-restrictions {
    .input-label {
      text-align: center;
      font-size: 20px;
      margin-bottom: 10px;
      color: #bbb;
    }

    .dietary-restriction-input {
      width: 100%;
    }
  }
}
