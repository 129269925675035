body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333;
}

body,
input,
textarea {
  font-family: "IBM Plex Serif", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App .content {
  padding: 30px; }

.App button.link {
  background: none;
  border: none;
  margin: 0;
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  color: inherit;
  font-weight: inherit;
  text-shadow: inherit; }

.Splash {
  background-image: url(/static/media/bg1.edccfe8a.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  text-align: center; }
  .Splash .container {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 15px 30px 120px 30px;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
  .Splash form {
    -webkit-flex-basis: 340px;
            flex-basis: 340px;
    margin-top: 15px;
    position: relative; }
    .Splash form input {
      background: rgba(0, 0, 0, 0.8);
      line-height: 40px;
      font-size: 32px;
      color: white;
      border: 0;
      padding: 20px;
      text-align: center;
      outline: none;
      border: 1px solid rgba(255, 255, 255, 0.3);
      box-shadow: 0 1px 3px rgba(255, 255, 255, 0.15);
      border-radius: 0; }
      .Splash form input:focus {
        border: 1px solid rgba(255, 255, 255, 0.6);
        box-shadow: 0 2px 10px rgba(255, 255, 255, 0.8); }
      .Splash form input.submit-button {
        cursor: pointer;
        -webkit-appearance: none; }
      .Splash form input.invite-code-input {
        text-transform: uppercase;
        letter-spacing: 8px;
        width: 180px;
        margin-right: 10px; }
        .Splash form input.invite-code-input::-webkit-input-placeholder {
          letter-spacing: normal;
          text-transform: none; }
        .Splash form input.invite-code-input::-ms-input-placeholder {
          letter-spacing: normal;
          text-transform: none; }
        .Splash form input.invite-code-input::placeholder {
          letter-spacing: normal;
          text-transform: none; }
    .Splash form .error {
      position: absolute;
      top: 96px;
      margin: 0 auto;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.8);
      border: 1px solid rgba(255, 255, 255, 0.3);
      padding: 10px;
      width: 284px;
      color: #d9742a;
      text-transform: capitalize; }
  .Splash .seal {
    width: 100px;
    display: inline-block;
    margin-right: 20px;
    margin-top: 15px; }

.Home .invite-banner {
  overflow: auto;
  background-image: url(/static/media/bg2.1ecc4e56.jpg);
  background-position: center center;
  background-size: cover;
  height: 800px;
  color: white;
  text-align: center;
  font-size: 32px;
  position: relative; }
  .Home .invite-banner p {
    margin: 0;
    position: relative;
    z-index: 2; }
    .Home .invite-banner p.invite-text {
      margin: 50px auto;
      padding: 30px;
      max-width: 530px; }
      .Home .invite-banner p.invite-text img {
        max-width: 100%; }
    .Home .invite-banner p.date {
      font-size: 24px;
      font-family: "Playfair Display SC", serif;
      position: absolute;
      bottom: 75px;
      padding: 0 30px;
      left: 0;
      right: 0; }
  .Home .invite-banner .overlay-shadow {
    width: 100%;
    position: absolute;
    z-index: 1; }
    .Home .invite-banner .overlay-shadow.top {
      height: 300px;
      background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
      top: 0; }
    .Home .invite-banner .overlay-shadow.bottom {
      height: 250px;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
      bottom: 0; }

.Home .sections {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: stretch;
          align-items: stretch;
  padding: 10px 0 0 10px;
  background-color: #111; }
  .Home .sections .section {
    padding: 30px;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-basis: 410px;
            flex-basis: 410px;
    margin: 0 10px 10px 0;
    text-decoration: none;
    color: white;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #7c6565;
    height: 320px;
    transition: all 0.4s;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    z-index: 1; }
    .Home .sections .section::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      background: inherit;
      transition: inherit; }
    .Home .sections .section:hover::before {
      -webkit-transform: scale(1.025);
              transform: scale(1.025); }
    .Home .sections .section.story {
      background-image: url(/static/media/story-header.fdb25c2b.jpg); }
    .Home .sections .section.details {
      background-image: url(/static/media/kbyg.6dbe438c.jpg); }
    .Home .sections .section.guide {
      background-image: url(/static/media/travel.dc24565e.jpg); }
    .Home .sections .section.rsvp {
      background-image: url(/static/media/rsvp.f9fb970d.jpg); }
    .Home .sections .section.registry {
      background-image: url(/static/media/registry.563ab932.jpg); }
    .Home .sections .section h3,
    .Home .sections .section .subtext {
      position: relative;
      z-index: 3;
      text-shadow: 0 2px 12px rgba(0, 0, 0, 0.8); }
    .Home .sections .section h3 {
      padding: 0;
      margin: 0;
      font-size: 24px; }
    .Home .sections .section .subtext {
      opacity: 0;
      transition: opacity 0.4s;
      font-size: 18px;
      font-weight: 500;
      max-width: 280px; }
    .Home .sections .section:hover .subtext {
      opacity: 1; }
    @media (hover: none) {
      .Home .sections .section .subtext {
        opacity: 1; } }

.PhotoWall .glass {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 5;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .PhotoWall .glass .photo {
    max-width: 100%;
    max-height: calc(100% - 93px);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .PhotoWall .glass .photo img {
      max-width: 100%;
      max-height: calc(100% - 93px); }
    .PhotoWall .glass .photo .spotlight-header {
      width: 100%;
      height: 50px;
      background: rgba(0, 0, 0, 0.8);
      color: white;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-items: center;
              align-items: center;
      padding: 30px 0; }
      .PhotoWall .glass .photo .spotlight-header .photo-info {
        padding-left: 30px; }
        .PhotoWall .glass .photo .spotlight-header .photo-info .photo-title {
          font-weight: 700;
          font-size: 20px;
          padding: 0;
          margin: 0; }
        .PhotoWall .glass .photo .spotlight-header .photo-info .where-when {
          padding: 0;
          margin: 10px 0 0;
          font-size: 16px;
          color: #ddd; }
      .PhotoWall .glass .photo .spotlight-header .close-icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 30px;
        -webkit-mask-position: center center;
                mask-position: center center;
        -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
        -webkit-mask-size: 20px 20px;
                mask-size: 20px 20px;
        cursor: pointer;
        background-color: white;
        -webkit-mask-image: url(/static/media/close.c4c396d3.svg);
                mask-image: url(/static/media/close.c4c396d3.svg); }

.PhotoWall .wall {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 10px 0 0 10px;
  background-color: #111;
  -webkit-justify-content: center;
          justify-content: center; }
  .PhotoWall .wall .photo {
    display: block;
    -webkit-flex-basis: auto;
            flex-basis: auto;
    position: relative;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    height: 150px;
    overflow: hidden;
    text-align: center;
    transition: all 0.4s;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    text-decoration: none;
    z-index: 1;
    margin: 0 10px 10px 0;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    max-width: 400px; }
    .PhotoWall .wall .photo:hover {
      -webkit-filter: none;
              filter: none; }
    .PhotoWall .wall .photo::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      background: inherit;
      transition: inherit;
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%); }
    .PhotoWall .wall .photo:hover::before {
      -webkit-filter: none;
              filter: none;
      -webkit-transform: scale(1.025);
              transform: scale(1.025); }
    .PhotoWall .wall .photo img {
      height: 150px;
      opacity: 0; }

.Story .header {
  background-image: url(/static/media/story-header.fdb25c2b.jpg); }
  .Story .header .content {
    color: white;
    padding: 0 60px 90px;
    max-width: 400px;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0.4);
    position: relative;
    z-index: 2; }
    .Story .header .content h1 {
      padding: 60px 0 30px;
      text-align: center; }
    .Story .header .content strong {
      font-size: 18px; }
  .Story .header .overlay-shadow.bottom {
    background: linear-gradient(rgba(0, 0, 0, 0), black); }

.Details .map {
  overflow: auto;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.Details .header {
  background-image: url(/static/media/meadowood-bg.1479c7e4.jpg);
  height: 650px; }

.Details .event-logistics {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: stretch;
          align-items: stretch;
  padding: 15px 30px; }
  .Details .event-logistics .overview {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-basis: 330px;
            flex-basis: 330px; }
  .Details .event-logistics .section {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    margin: 15px 0; }
    .Details .event-logistics .section.schedule {
      -webkit-flex-grow: 2;
              flex-grow: 2; }
    .Details .event-logistics .section p {
      text-align: center;
      font-size: 22px;
      color: #666;
      margin: 10px 0; }
      .Details .event-logistics .section p.date {
        font-family: "Playfair Display SC", serif; }
      .Details .event-logistics .section p.address a {
        background-color: #c4c4c4;
        border-radius: 10px;
        padding: 10px 20px;
        display: inline-block;
        text-decoration: none;
        color: white;
        transition: all 0.3s; }
        .Details .event-logistics .section p.address a:hover {
          background-color: #aaa; }
        .Details .event-logistics .section p.address a:active {
          background-color: #777; }
    .Details .event-logistics .section .venue-name {
      font-family: "Playfair Display SC", serif;
      font-weight: bold;
      font-size: 22px; }
    .Details .event-logistics .section table {
      width: 100%;
      border-collapse: collapse; }
      .Details .event-logistics .section table thead {
        background-color: #f6f6f6;
        text-align: left; }
      .Details .event-logistics .section table th,
      .Details .event-logistics .section table td {
        padding: 10px 12px;
        border: 1px solid #ddd; }

.Details .map {
  height: 728px;
  background-image: url(/static/media/meadowood-map.44253771.jpg); }

@media only screen and (max-width: 810px) {
  .Details .map {
    height: 300px; } }

.Details .dress-code {
  margin-bottom: 30px; }
  .Details .dress-code h2,
  .Details .dress-code p {
    text-align: center; }
  .Details .dress-code p {
    font-size: 20px;
    padding: 0 60px; }
  .Details .dress-code .sections {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: stretch;
            align-items: stretch; }
    .Details .dress-code .sections .section {
      -webkit-flex-grow: 1;
              flex-grow: 1;
      -webkit-flex-basis: 330px;
              flex-basis: 330px;
      padding: 60px 60px 30px;
      text-align: center; }
      .Details .dress-code .sections .section .icon {
        width: 75px;
        height: 75px;
        margin: 0 auto;
        background-position: center center;
        background-size: 100%;
        margin-bottom: 40px; }
        .Details .dress-code .sections .section .icon.tuxedo {
          background-image: url(/static/media/tuxedo.5acefaaa.svg); }
        .Details .dress-code .sections .section .icon.dress {
          background-image: url(/static/media/dress.595c0c9f.svg); }

@media only screen and (max-width: 810px) {
  .Guide .table-container {
    overflow-x: auto;
    margin: 0 -30px; } }

.Guide table {
  width: 100%;
  border-collapse: collapse;
  min-width: 750px; }
  .Guide table thead {
    background-color: #f6f6f6; }
  .Guide table th,
  .Guide table td {
    padding: 10px 12px;
    border: 1px solid #ddd; }
  .Guide table td:first-child:hover {
    background: rgba(200, 45, 45, 0.08); }
  .Guide table td:first-child:active {
    background: rgba(200, 45, 45, 0.16); }
  .Guide table tr {
    text-align: left; }
  .Guide table a {
    text-decoration: none;
    color: #c82d2d;
    display: block;
    padding: 10px 12px;
    margin: -10px -12px; }

.Guide .img-links {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 5px -5px 5px; }
  .Guide .img-links a {
    display: inline-block;
    margin: 5px;
    -webkit-flex-basis: 400px;
            flex-basis: 400px;
    -webkit-flex-grow: 1;
            flex-grow: 1; }
    .Guide .img-links a img {
      width: 100%; }

.Gallery {
  display: -webkit-flex;
  display: flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: stretch;
          align-items: stretch;
  padding: 10px 0 0 10px;
  background-color: #111; }
  .Gallery a {
    margin: 0 10px 10px 0;
    display: block;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-basis: 481px;
            flex-basis: 481px;
    height: 300px;
    transition: all 0.4s;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    z-index: 1;
    cursor: pointer;
    text-decoration: none; }
    .Gallery a .guide-title {
      position: absolute;
      z-index: 3;
      color: white;
      text-decoration: none;
      font-weight: bold;
      font-size: 32px;
      text-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
      bottom: 30px;
      display: block;
      width: 100%;
      text-align: center; }
    .Gallery a::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      background: inherit;
      transition: inherit; }
    .Gallery a:hover::before {
      -webkit-transform: scale(1.025);
              transform: scale(1.025); }
    .Gallery a.food {
      background-image: url(/static/media/food.f836233c.jpg); }
    .Gallery a.wineries {
      background-image: url(/static/media/wineries.a96eddf4.jpg); }
    .Gallery a.lodging {
      background-image: url(/static/media/lodging.971a49db.jpg); }
    .Gallery a.activities {
      background-image: url(/static/media/activities.6692a6a3.jpg); }

.Measure {
  position: relative; }
  .Measure .label {
    position: relative;
    z-index: 2;
    top: -1px; }
  .Measure .bar {
    position: absolute;
    height: 24px;
    top: -3px;
    left: -4px;
    background-color: #ddd;
    z-index: 1;
    border-radius: 2px; }

.Banner {
  height: 630px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative; }
  .Banner h1 {
    position: relative; }
    .Banner h1 .arrow {
      border: solid white;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 4px;
      margin-right: 10px;
      position: relative;
      bottom: 3px; }
      .Banner h1 .arrow.left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg); }
  .Banner h1,
  .Banner h2 {
    padding: 30px;
    margin: 0;
    z-index: 2; }
  .Banner h2,
  .Banner h1 a {
    text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.5);
    color: white;
    text-decoration: none; }
  .Banner h2 {
    text-align: center;
    font-size: 50px;
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0; }
  .Banner .overlay-shadow {
    width: 100%;
    position: absolute;
    z-index: 1; }
    .Banner .overlay-shadow.top {
      width: 600px;
      height: 300px;
      background: radial-gradient(600px 300px ellipse at 0% 0%, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
      top: 0;
      left: 0; }
    .Banner .overlay-shadow.bottom {
      height: 300px;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
      bottom: 0; }
  .Banner.food {
    background-image: url(/static/media/food.f836233c.jpg); }
  .Banner.wineries {
    background-image: url(/static/media/wineries.a96eddf4.jpg); }
  .Banner.lodging {
    background-image: url(/static/media/lodging.971a49db.jpg); }
  .Banner.activities {
    background-image: url(/static/media/activities.6692a6a3.jpg); }

.DynamicInput {
  border: 0;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  position: relative; }
  .DynamicInput .dynamic-input {
    outline: none;
    width: calc(100% - 95px);
    padding: 0 80px 0 15px;
    background: none;
    border: none;
    line-height: 50px;
    font-size: 18px;
    color: #888; }
    .DynamicInput .dynamic-input:focus {
      box-shadow: 0 1px 10px rgba(255, 255, 255, 0.3); }
    .DynamicInput .dynamic-input::-webkit-input-placeholder {
      color: #555; }
    .DynamicInput .dynamic-input::-ms-input-placeholder {
      color: #555; }
    .DynamicInput .dynamic-input::placeholder {
      color: #555; }
  .DynamicInput .right-button {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 15px;
    height: 30px;
    padding: 0 12px;
    line-height: 30px;
    font-size: 14px;
    text-transform: uppercase;
    color: #aaa;
    font-weight: 500; }
  .DynamicInput .dynamic-submit {
    cursor: pointer;
    outline: none;
    -webkit-appearance: none; }

.GuestCard {
  margin-bottom: 10px;
  background-color: rgba(68, 68, 68, 0.6);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  padding: 8px 12px;
  padding: 30px;
  -webkit-flex-basis: 480px;
          flex-basis: 480px;
  margin: 10px 10px 0; }
  .GuestCard.undecided .food-options {
    opacity: 0.4; }
  .GuestCard.undecided .food-options .vert-switch .switch-option {
    cursor: default; }
  .GuestCard.locked .food-options .vert-switch .switch-option,
  .GuestCard.locked .switch.horiz-switch .switch-option {
    cursor: default; }
  .GuestCard .guest-name {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    color: #ccc;
    padding: 0;
    margin: 0 0 20px; }
  .GuestCard .label {
    font-size: 22px;
    padding: 0;
    margin: 10px 0 16px;
    color: #bbb;
    text-align: center; }
  .GuestCard .switch {
    background-color: rgba(0, 0, 0, 0.6);
    margin-bottom: 16px;
    border-radius: 25px; }
    .GuestCard .switch.horiz-switch {
      height: 50px;
      text-align: center;
      position: relative;
      padding: 0 5px; }
      .GuestCard .switch.horiz-switch .selector {
        position: absolute;
        width: 33%;
        height: 40px;
        border-radius: 20px;
        background-color: rgba(102, 102, 102, 0.5);
        z-index: 1;
        top: 5px;
        transition: left 0.3s; }
        .GuestCard .switch.horiz-switch .selector.decline {
          left: 5px; }
        .GuestCard .switch.horiz-switch .selector.undecided {
          left: 33.33%; }
        .GuestCard .switch.horiz-switch .selector.accept {
          left: calc(67% - 5px); }
      .GuestCard .switch.horiz-switch .switch-option {
        display: inline-block;
        width: 33.33%;
        height: 40px;
        border-radius: 20px;
        margin: 5px 0;
        -webkit-mask-position: center center;
                mask-position: center center;
        -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
        -webkit-mask-size: 30px 30px;
                mask-size: 30px 30px;
        position: relative;
        z-index: 2;
        cursor: pointer; }
        .GuestCard .switch.horiz-switch .switch-option + .switch-option {
          margin-left: 0; }
        .GuestCard .switch.horiz-switch .switch-option.decline {
          background-color: #c82d2d;
          -webkit-mask-image: url(/static/media/decline.6a3accc4.svg);
                  mask-image: url(/static/media/decline.6a3accc4.svg); }
        .GuestCard .switch.horiz-switch .switch-option.undecided {
          background-color: #aaa;
          -webkit-mask-image: url(/static/media/undecided.87cde89d.svg);
                  mask-image: url(/static/media/undecided.87cde89d.svg); }
        .GuestCard .switch.horiz-switch .switch-option.accept {
          background-color: #2dc82d;
          -webkit-mask-image: url(/static/media/accept.ee09cdfc.svg);
                  mask-image: url(/static/media/accept.ee09cdfc.svg); }
        .GuestCard .switch.horiz-switch .switch-option.loading {
          -webkit-mask-image: url(/static/media/spinner.be00fc4a.svg);
                  mask-image: url(/static/media/spinner.be00fc4a.svg); }
    .GuestCard .switch.vert-switch {
      position: relative; }
      .GuestCard .switch.vert-switch .selector {
        position: absolute;
        width: calc(100% - 10px);
        left: 5px;
        height: 40px;
        border-radius: 20px;
        z-index: 1;
        transition: top 0.3s;
        background: none; }
        .GuestCard .switch.vert-switch .selector.veg {
          background-color: rgba(102, 102, 102, 0.5);
          top: 5px; }
        .GuestCard .switch.vert-switch .selector.fish {
          background-color: rgba(102, 102, 102, 0.5);
          top: 55px; }
        .GuestCard .switch.vert-switch .selector.meat {
          background-color: rgba(102, 102, 102, 0.5);
          top: 105px; }
      .GuestCard .switch.vert-switch .switch-option {
        font-size: 22px;
        padding: 5px 18px 5px 60px;
        line-height: 40px;
        color: #888;
        position: relative;
        z-index: 2;
        cursor: pointer; }
        .GuestCard .switch.vert-switch .switch-option.active {
          background-color: white; }
        .GuestCard .switch.vert-switch .switch-option span {
          position: absolute;
          top: 10px;
          left: 18px;
          display: block;
          width: 30px;
          height: 30px;
          -webkit-mask-position: center center;
                  mask-position: center center;
          -webkit-mask-repeat: no-repeat;
                  mask-repeat: no-repeat;
          -webkit-mask-size: 30px 30px;
                  mask-size: 30px 30px; }
          .GuestCard .switch.vert-switch .switch-option span.fish {
            background-color: #6c6cd6;
            -webkit-mask-image: url(/static/media/fish.0f73c064.svg);
                    mask-image: url(/static/media/fish.0f73c064.svg); }
          .GuestCard .switch.vert-switch .switch-option span.veg {
            background-color: #2dc82d;
            -webkit-mask-image: url(/static/media/veg.cb954027.svg);
                    mask-image: url(/static/media/veg.cb954027.svg); }
          .GuestCard .switch.vert-switch .switch-option span.meat {
            background-color: #c97d4d;
            -webkit-mask-image: url(/static/media/meat.3075d471.svg);
                    mask-image: url(/static/media/meat.3075d471.svg); }
          .GuestCard .switch.vert-switch .switch-option span.loading {
            -webkit-mask-image: url(/static/media/spinner.be00fc4a.svg);
                    mask-image: url(/static/media/spinner.be00fc4a.svg); }
  .GuestCard .dietary-restrictions .input-label {
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
    color: #bbb; }
  .GuestCard .dietary-restrictions .dietary-restriction-input {
    width: 100%; }

.RSVP {
  position: relative;
  background-color: #222;
  color: #bbb; }
  .RSVP .header {
    color: #bbb;
    background-image: url(/static/media/rsvp-invitation.ea57907f.jpg);
    background-position: left !important;
    padding-bottom: 100px;
    height: 500px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5); }
    .RSVP .header .rsvp-status {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 40px;
      text-align: center;
      color: white;
      z-index: 2;
      font-size: 32px; }
      .RSVP .header .rsvp-status p {
        text-shadow: 0px 2px 25px black; }
  @media only screen and (max-width: 865px) {
    .RSVP .header {
      height: 350px; } }
  .RSVP .guest-container {
    padding: 60px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly; }

.Site.RSVP.header.overlay-shadow.bottom {
  background: linear-gradient(rgba(0, 0, 0, 0), #222); }

.Site {
  overflow: scroll; }
  .Site .container {
    max-width: 1440px;
    margin: 0 auto; }
  .Site .header {
    position: relative;
    overflow: auto;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }
    .Site .header .overlay-shadow {
      width: 100%;
      position: absolute;
      z-index: 1; }
      .Site .header .overlay-shadow.top {
        height: 200px;
        background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
        top: 0;
        left: 0; }
      .Site .header .overlay-shadow.bottom {
        height: 200px;
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
        bottom: 0; }
    .Site .header h1 {
      padding: 30px;
      position: relative;
      z-index: 2;
      margin: 0;
      color: white;
      text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.5); }
  .Site .nav {
    margin: 40px 0 0;
    list-style-type: none;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: #2b2b2b;
    border-radius: 8px 8px 0 0;
    -webkit-align-items: stretch;
            align-items: stretch;
    font-family: "Playfair Display SC", serif;
    font-weight: bold; }
    .Site .nav .ni {
      padding: 0 16px; }
    .Site .nav button {
      padding: 0 20px; }
    .Site .nav .menu {
      display: none; }
    .Site .nav .seal {
      width: 100px;
      position: relative; }
      .Site .nav .seal .seal-container {
        width: 100px;
        height: 100px;
        position: absolute;
        top: -22px;
        z-index: 5;
        background: black;
        border-radius: 50px; }
    .Site .nav a {
      display: inline-block; }
      .Site .nav a span {
        display: inline-block;
        padding: 16px 4px; }
      .Site .nav a.active span {
        border-bottom: 3px solid #ddd; }
      .Site .nav a.active:hover {
        color: #dddddd; }
    .Site .nav a,
    .Site .nav button {
      display: inline-block;
      cursor: pointer;
      text-decoration: none;
      color: #dddddd; }
      .Site .nav a:hover,
      .Site .nav button:hover {
        color: #c82d2d; }
      .Site .nav a:active,
      .Site .nav button:active {
        color: #a02d2d; }
    .Site .nav .spacer {
      display: inline-block;
      width: 40px; }
    .Site .nav button {
      height: 53px; }
  @media only screen and (max-width: 991px) {
    .Site .nav {
      position: relative;
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-left: 60px; }
      .Site .nav .seal-nav-item {
        position: absolute;
        right: 10px;
        top: 0; }
      .Site .nav a:not(.active),
      .Site .nav button,
      .Site .nav .spacer {
        display: none; }
      .Site .nav .menu {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 53px;
        height: 53px;
        -webkit-mask-image: url(/static/media/menu.c9940670.svg);
                mask-image: url(/static/media/menu.c9940670.svg);
        -webkit-mask-position: center center;
                mask-position: center center;
        -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
        -webkit-mask-size: 20px 20px;
                mask-size: 20px 20px;
        background-color: white;
        cursor: pointer; }
      .Site .nav.expanded .menu {
        -webkit-mask-image: url(/static/media/close.c4c396d3.svg);
                mask-image: url(/static/media/close.c4c396d3.svg);
        -webkit-mask-size: 16px 16px;
                mask-size: 16px 16px; }
      .Site .nav.expanded a,
      .Site .nav.expanded button {
        display: block;
        text-align: left; } }
  .Site .page {
    overflow: auto;
    background: white; }
  .Site .footer {
    text-align: center;
    padding: 30px 0;
    margin: 30px 30px 150px;
    color: #111;
    font-weight: 500; }
    .Site .footer .heart {
      transition: color 0.4s; }
    .Site .footer:hover .heart {
      color: #901a1a;
      cursor: -webkit-grab;
      cursor: grab; }

@-webkit-keyframes animatedBackground {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 100% 0; } }

@keyframes animatedBackground {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 100% 0; } }
  .Site .buttercup {
    background: url(/static/media/buttercup.534fc92d.jpg);
    position: fixed;
    background-size: contain;
    background-position: center center;
    -webkit-animation: animatedBackground 10s linear infinite;
            animation: animatedBackground 10s linear infinite;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    cursor: pointer; }

