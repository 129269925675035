.Measure {
  position: relative;

  .label {
    position: relative;
    z-index: 2;
    top: -1px;
  }

  .bar {
    position: absolute;
    height: 24px;
    top: -3px;
    left: -4px;
    background-color: #ddd;
    z-index: 1;
    border-radius: 2px;
  }
}
