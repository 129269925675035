.Site {
  overflow: scroll;

  .container {
    max-width: 1440px;
    margin: 0 auto;
  }

  .header {
    position: relative;
    overflow: auto;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .overlay-shadow {
      width: 100%;
      position: absolute;
      z-index: 1;

      &.top {
        height: 200px;
        background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
        top: 0;
        left: 0;
      }

      &.bottom {
        height: 200px;
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
        bottom: 0;
      }
    }

    h1 {
      padding: 30px;
      position: relative;
      z-index: 2;
      margin: 0;
      color: white;
      text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.5);
    }
  }

  .nav {
    margin: 40px 0 0;
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
    background-color: #2b2b2b;
    border-radius: 8px 8px 0 0;
    align-items: stretch;
    font-family: "Playfair Display SC", serif;
    font-weight: bold;

    .ni {
      padding: 0 16px;
    }

    button {
      padding: 0 20px;
    }

    .menu {
      display: none;
    }

    .seal {
      width: 100px;
      position: relative;

      .seal-container {
        width: 100px;
        height: 100px;
        position: absolute;
        top: -22px;
        z-index: 5;
        background: rgba(0, 0, 0, 1);
        border-radius: 50px;
      }
    }

    a {
      display: inline-block;
      span {
        display: inline-block;
        padding: 16px 4px;
      }
      &.active {
        span {
          border-bottom: 3px solid #ddd;
        }

        &:hover {
          color: #dddddd;
        }
      }
    }

    a,
    button {
      display: inline-block;
      cursor: pointer;
      text-decoration: none;
      color: #dddddd;

      &:hover {
        color: rgb(200, 45, 45);
      }

      &:active {
        color: rgb(160, 45, 45);
      }
    }

    .spacer {
      display: inline-block;
      width: 40px;
    }

    button {
      height: 53px;
    }
  }

  @media only screen and (max-width: 991px) {
    .nav {
      position: relative;
      flex-direction: column;
      padding-left: 60px;

      .seal-nav-item {
        position: absolute;
        right: 10px;
        top: 0;
      }

      a:not(.active),
      button,
      .spacer {
        display: none;
      }

      .menu {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 53px;
        height: 53px;
        mask-image: url("./images/menu.svg");
        mask-position: center center;
        mask-repeat: no-repeat;
        mask-size: 20px 20px;
        background-color: white;
        cursor: pointer;
      }

      &.expanded {
        .menu {
          mask-image: url("./images/close.svg");
          mask-size: 16px 16px;
        }

        a,
        button {
          display: block;
          text-align: left;
        }
      }
    }
  }

  .page {
    overflow: auto;
    background: white;
  }

  .footer {
    text-align: center;
    padding: 30px 0;
    margin: 30px 30px 150px;
    color: #111;
    font-weight: 500;

    .heart {
      transition: color 0.4s;
    }

    &:hover {
      .heart {
        color: rgb(144, 26, 26);
        cursor: grab;
      }
    }
  }

  @keyframes animatedBackground {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 0;
    }
  }

  .buttercup {
    background: url("./images/buttercup.jpg");
    position: fixed;
    background-size: contain;
    background-position: center center;
    animation: animatedBackground 10s linear infinite;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    cursor: pointer;
  }
}
