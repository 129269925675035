.DynamicInput {
  border: 0;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  position: relative;

  .dynamic-input {
    outline: none;
    width: calc(100% - 95px);
    padding: 0 80px 0 15px;
    background: none;
    border: none;
    line-height: 50px;
    font-size: 18px;
    color: #888;

    &:focus {
      box-shadow: 0 1px 10px rgba(white, 0.3);
    }

    &::placeholder {
      color: #555;
    }
  }

  .right-button {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: rgba(white, 0.2);
    border: none;
    border-radius: 15px;
    height: 30px;
    padding: 0 12px;
    line-height: 30px;
    font-size: 14px;
    text-transform: uppercase;
    color: #aaa;
    font-weight: 500;
  }

  .dynamic-submit {
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;
  }
}
