.PhotoWall {
  .glass {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(black, 0.8);
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .photo {
      max-width: 100%;
      max-height: calc(100% - 93px);
      display: flex;
      flex-direction: column;

      img {
        max-width: 100%;
        max-height: calc(100% - 93px);
      }

      .spotlight-header {
        width: 100%;
        height: 50px;
        background: rgba(black, 0.8);
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 0;

        .photo-info {
          padding-left: 30px;

          .photo-title {
            font-weight: 700;
            font-size: 20px;
            padding: 0;
            margin: 0;
          }

          .where-when {
            padding: 0;
            margin: 10px 0 0;
            font-size: 16px;
            color: #ddd;
          }
        }

        .close-icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 30px;
          mask-position: center center;
          mask-repeat: no-repeat;
          mask-size: 20px 20px;
          cursor: pointer;
          background-color: white;
          mask-image: url("./images/close.svg");
        }
      }
    }
  }

  .wall {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0 0 10px;
    background-color: #111;
    justify-content: center;

    .photo {
      display: block;
      flex-basis: auto;
      position: relative;
      flex-grow: 1;
      height: 150px;
      overflow: hidden;
      text-align: center;
      transition: all 0.4s;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      cursor: pointer;
      text-decoration: none;
      z-index: 1;
      margin: 0 10px 10px 0;
      filter: grayscale(100%);
      max-width: 400px;

      &:hover {
        filter: none;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        background: inherit;
        transition: inherit;
        filter: grayscale(100%);
      }

      &:hover::before {
        filter: none;
        transform: scale(1.025);
      }

      img {
        height: 150px;
        opacity: 0;
      }
    }
  }
}
