.App {
  .content {
    padding: 30px;
  }

  button.link {
    background: none;
    border: none;
    margin: 0;
    text-decoration: none;
    font-family: inherit;
    font-size: inherit;
    outline: none;
    color: inherit;
    font-weight: inherit;
    text-shadow: inherit;
  }
}
