.Splash {
  background-image: url("./images/bg1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  text-align: center;

  .container {
    background: linear-gradient(
      rgba(black, 0.6),
      rgba(black, 0.5),
      rgba(black, 0)
    );
    display: flex;
    align-items: center;
    padding: 15px 30px 120px 30px;
    justify-content: center;
    flex-wrap: wrap;
  }

  form {
    flex-basis: 340px;
    margin-top: 15px;
    position: relative;

    input {
      background: rgba(0, 0, 0, 0.8);
      line-height: 40px;
      font-size: 32px;
      color: white;
      border: 0;
      padding: 20px;
      text-align: center;
      outline: none;
      border: 1px solid rgba(white, 0.3);
      box-shadow: 0 1px 3px rgba(white, 0.15);
      border-radius: 0;

      &:focus {
        border: 1px solid rgba(white, 0.6);
        box-shadow: 0 2px 10px rgba(white, 0.8);
      }

      &.submit-button {
        cursor: pointer;
        -webkit-appearance: none;
      }

      &.invite-code-input {
        text-transform: uppercase;
        letter-spacing: 8px;
        width: 180px;
        margin-right: 10px;

        &::placeholder {
          letter-spacing: normal;
          text-transform: none;
        }
      }
    }

    .error {
      position: absolute;
      top: 96px;
      margin: 0 auto;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.8);
      border: 1px solid rgba(white, 0.3);
      padding: 10px;
      width: 304px - 20px;
      color: rgb(217, 116, 42);
      text-transform: capitalize;
    }
  }

  .seal {
    width: 100px;
    display: inline-block;
    margin-right: 20px;
    margin-top: 15px;
  }
}
