.Banner {
  height: 630px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  h1 {
    position: relative;

    .arrow {
      border: solid white;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 4px;
      margin-right: 10px;
      position: relative;
      bottom: 3px;

      &.left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }
    }
  }

  h1,
  h2 {
    padding: 30px;
    margin: 0;
    z-index: 2;
  }

  h2,
  h1 a {
    text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.5);
    color: white;
    text-decoration: none;
  }

  h2 {
    text-align: center;
    font-size: 50px;
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
  }

  .overlay-shadow {
    width: 100%;
    position: absolute;
    z-index: 1;

    &.top {
      width: 600px;
      height: 300px;
      background: radial-gradient(
        600px 300px ellipse at 0% 0%,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0)
      );
      top: 0;
      left: 0;
    }

    &.bottom {
      height: 300px;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
      bottom: 0;
    }
  }

  &.food {
    background-image: url("./images/food.jpg");
  }

  &.wineries {
    background-image: url("./images/wineries.jpg");
  }

  &.lodging {
    background-image: url("./images/lodging.jpg");
  }

  &.activities {
    background-image: url("./images/activities.jpg");
  }
}
