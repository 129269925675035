.Story {
  .header {
    background-image: url("./images/story-header.jpg");

    .content {
      color: white;
      padding: 0 60px 90px;
      max-width: 400px;
      text-align: justify;
      background-color: rgba(black, 0.4);
      position: relative;
      z-index: 2;

      h1 {
        padding: 60px 0 30px;
        text-align: center;
      }

      strong {
        font-size: 18px;
      }
    }

    .overlay-shadow.bottom {
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    }
  }
}
