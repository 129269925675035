.Guide {
  @media only screen and (max-width: 810px) {
    .table-container {
      overflow-x: auto;
      margin: 0 -30px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    min-width: 750px;

    thead {
      background-color: #f6f6f6;
    }

    th,
    td {
      padding: 10px 12px;
      border: 1px solid #ddd;
    }

    td:first-child {
      &:hover {
        background: rgba(200, 45, 45, 0.08);
      }

      &:active {
        background: rgba(200, 45, 45, 0.16);
      }
    }

    tr {
      text-align: left;
    }

    a {
      text-decoration: none;
      color: rgb(200, 45, 45);
      display: block;
      padding: 10px 12px;
      margin: -10px -12px;
    }
  }

  .img-links {
    display: flex;
    flex-wrap: wrap;
    margin: 5px -5px 5px;

    a {
      display: inline-block;
      margin: 5px;
      flex-basis: 400px;
      flex-grow: 1;

      img {
        width: 100%;
      }
    }
  }
}
