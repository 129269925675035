.Gallery {
  display: flex;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 10px 0 0 10px;
  background-color: #111;

  a {
    margin: 0 10px 10px 0;
    display: block;
    flex-grow: 1;
    flex-basis: 481px;
    height: 300px;
    transition: all 0.4s;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    z-index: 1;
    cursor: pointer;
    text-decoration: none;

    .guide-title {
      position: absolute;
      z-index: 3;
      color: white;
      text-decoration: none;
      font-weight: bold;
      font-size: 32px;
      text-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
      bottom: 30px;
      display: block;
      width: 100%;
      text-align: center;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      background: inherit;
      transition: inherit;
    }

    &:hover::before {
      transform: scale(1.025);
    }

    &.food {
      background-image: url("./images/food.jpg");
    }

    &.wineries {
      background-image: url("./images/wineries.jpg");
    }

    &.lodging {
      background-image: url("./images/lodging.jpg");
    }

    &.activities {
      background-image: url("./images/activities.jpg");
    }
  }
}
